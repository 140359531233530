$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;
$largeScreen: 1980px;

.complaintsHomePage {
    .pageTitle {
        text-align: center;
    }
   
}
.main-block {
    margin-top: 10px;
    margin-bottom: 20px;
    
  }
  .h3-center {
    text-align: center;
}
.matTable{
  padding-bottom: 24px;
  align-items: center;
}
.view-complaint-block{
  padding-bottom: 24px;
  width: 100%;
  background: var(--white);
  border-bottom: 2px dashed #00bcdc;
}
.view-block-mb{
  padding-bottom: 15px;
  width: 100%;
  background: var(--white);
  border-bottom: 2px dashed #00bcdc;
}
  .view-block{
    width:100%;
    margin-top: 15px;
    padding-top: 7px;
    border-top: 2px dashed #00bcdc;
    
    .pr-3 {
        padding-right: 3em;
    }
  }
  .mt-10{
       margin-bottom: 20px;
  }
  .fr-r{
float: right;
  }
  .border-bt{
    padding-bottom: 24px;
    margin-top: 25px;
    width: 100%;
    border-top: 2px dashed #00bcdc;
  }
  .details-center{
      text-align: center;
  }
.margin-text{
  font-size: larger;
    margin-top: 18px;
    margin-bottom: 15px;
    background-color: #C5FFEF;
}
inner-block{
  padding:20px;
}
.inner-vb{
  margin-bottom: 26px;
  margin-top: 15px;
}

.complaint-root{
  margin-top: 20px;
  margin-bottom: 20px;
  background: var(--white);
  border-radius: 10px;
  .pr-3 {
      padding-right: 3em;
  }
}
.title-heading{
  margin: 20px 0px 20px 0px;
}
.align-ctr{
  align-self: center;
}
.submit-btn{
  justify-content: center;
    margin: 18px 0px;
    
}
.btn-draft{
  margin:15px;
  justify-content: center;
}
.submit-d4{
  margin: 18px;
}
.button-submit{
  padding: 7px !important;
  .draft{
    margin: 15px !important;
  }
  
  
}
.btn-mr{
  margin: 23px 0px 6px 0px !important;
  text-align: center;
}
.mr-btm{
  margin-bottom: 20px;
}
.autocomplete-pr{
  background: "var(--input-bg-color)";
}
.complaint-id{
  justify-content: end;
  background: "var(--input-bg-color)"
}
.h4{
  background: "var(--input-bg-color)"
}
.date-id{
  background: "var(--input-bg-color)"
}
.upload-bt{
  border-bottom: 2px dashed #00bcdc;
}
.epBSWg{
  background: "var(--input-bg-color) !important"
}
.upload-form{
  padding-bottom: 9px;
}
.img-content{
  width: -webkit-fill-available;
}
.Table-margin {
  margin: 6px !important;
}
.digwJv {
  width: 331px;
  border: 1px solid #008AA6;
  background: var(--input-bg-color);
   border-radius:4px;
  padding-top: 8px;
  padding-bottom: 1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.pd-bt1{
  padding-bottom: 30px;
}
.dashboard-text-block {
  margin-bottom: 0;
  margin-top: 6px;
}
.dashboard-block {
  box-shadow: 1px 0px 4px 2px #ccc;
  padding: 12px;
  background-color: #EBF7F8;
  border-radius: 10px;
  margin-bottom: 10px;
  min-width: 100%;
  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    min-width: auto;
  }
}
.mr-btm{
  padding-top:30px !important;
}
