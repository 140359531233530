.lheading {
    font-size: 10px;
    margin: 0px;
    width:50%;
}
.heading{
    width:40%;
    margin:5px !important;
}

.main-heading{
    //display:flex;
    //justify-content:space-between;
}

.input-field {
    outline: none;
    border: 1px solid red;
}

.fields {
    //margin-top: 20px !important;
}

.outer-border {
    border: 1px solid black;
}

h3 {
    font-family: "SiemensSans-Black", sans-serif;
    color: var(--dark-blue);
    margin: 5px 0px 30px 0px;
}

.request-part {
    //position: relative;
    display: flex !important;
    flex-direction:column;
   // flex-direction: row-reverse;
    //margin-left: 31% !important;
    //position:relative;
    //left: 10%;
    margin-bottom: 20px;
}

.request-open {
   // margin-right: 20px;
}
.drop-down{
   // width:120% !important;
}
.submit{
    top:40%;

}
.date {
    display: flex;
    flex-direction: column;
    align-items: self-end;
    //max-width:100% !important;
    //width:50%;
}
.left{
    //margin-left:10%;
    
}
.datatwo-part {
    border: 2px solid var(--blue-green);
    width: 100%;
    padding: 8px;
    color: black;
    //font-size: 20px;
    font-size: 0.9rem;
    text-align: center;
    cursor: pointer;
    //color: var(--dark-blue);
    //font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    ////////font-weight: 400;
    //////line-height: 1;
    //letter-spacing: 0.00938em;
    font-family: "SiemensSans-Bold", sans-serif;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 10px;
}
.left-div{
    display:flex;
    flex-direction:column;
    //width:50%;
}
.radio-btn{
    display:flex;
    flex-direction:row;
    margin-top:8%;
}
.yes{
    margin-left:10px;
    margin-right:10px;
}
.tab-section{
    display:flex;
    width:100%;
    margin:17px 0px;

}
.tab1 {
    width: 50%;
    //border: 2px solid var(--blue-green);
    padding: 17px;
    text-align: center;
    cursor: pointer;
    margin-right: 5px;
    color: black;
    font-size: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    background: var(--landing-page-card-bg-color);
    box-shadow: 0 0 10px var(--light-sand);
    border-radius: 8px;
}
.tab2 {
    width: 50%;
    //border: 2px solid var(--blue-green);
    padding: 17px;
    text-align: center;
    cursor: pointer;
    margin-left: 5px;
    color: black;
    font-size: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    background: var(--landing-page-card-bg-color);
    box-shadow: 0 0 10px var(--light-sand);
    border-radius: 8px;
}
.data-part {
    margin: 10px;
    //padding:2px;
    
}
.datatwo-part {
    background: var(--landing-page-card-bg-color);
    padding:17px;
    box-shadow: 0 0 10px var(--light-sand);
    border-radius: 8px;
}
.tab-part {
    margin: 10px;
    
    //padding: 2px;
    //background: var(--landing-page-card-bg-color);
    box-shadow: 0 0 10px var(--light-sand);
    border-radius: 8px;
}
.upload-section {
    display: flex;
    flex-direction: column;
    //justify-content: center;
   // align-items: center;
}
.tab-submit {
    text-align: center;
    margin-top:5px;
}
//new one tab
.tab-section {
    //display: flex;
}

.tab1 {
    width: 50%;
    border: 1px solid black;
    margin-right: 5px;
    //background: var(--landing-page-card-bg-color);
    //opacity: 0.5;
}

.tab2 {
    width: 50%;
    border: 1px solid black;
    margin-left: 5px;
    //background: var(--landing-page-card-bg-color);
    //opacity: 0.5;
    
}


.tab-one {
    border: 1px solid black;
    margin-top: 2%;
    text-align:center;
    //height: 100px;
    //background:grey;
}

.tab-two {
    border: 1px solid black;
    margin-top: 2%;
    //height: 100px;
}


.active {
    width: 90%;
    //border-bottom: 5px solid var(--landing-page-card-bg-color) border: 1px solid black;
    margin-right: 5px;
    background: var(--landing-page-card-bg-color);
   // background-color: greenyellow;
    border-radius: 7px;
}
.inactive {
    background-color: var(--landing-page-card-bg-color);
}
.extra{
    background-color:grey;
}
.check-box{
    display:flex;
}
.single-line {
    border-top: 1px dashed var(--landing-page-card-bg-color);
}
.checked-field{
    width:20px;
}
.checked-filedtrm{
    width:20px;
    //display:flex !important;
}
.tab-portion{
    margin-top:10px;
}
.label-font{
    font-size:17px !important;
}
.mandatory-fields {
    border-bottom: 2px dashed #008aa6;
    display:flex;
    padding-bottom:15px;
    //margin-bottom:20px;
}
.spr-fields {
    border-bottom: 2px dashed #008aa6;
    display: flex;
    padding:10px;
    //padding-bottom: 15px;
    //margin-bottom:20px;
}
.supplier-fields {
    display: flex;
    margin-top:8px !important;
}
.btn-section {
    //text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.po-upload {
    
    border-top: 2px dashed #008aa6;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}
.part-two {
    padding-bottom: 10px;
}
.tab-onebtnupload{
    display:flex;
    justify-content:center;
    align-items:center;
}
.see-data{
    margin:10px;
}
//aTag: {
    //color: `#00BCBC`, fontStyle: `italic`, borderBottom: `1px solid #00BCBC`, fontWeight: `bold`,
//}
.link-filed {
    color: #00BCBC;
    font-style: italic;
    border-bottom: 1px solid #00BCBC;
    font-weight: bold;
    text-align:center;
    
}

.link-filed1 {
    // outline:none;
    //border-bottom: 1px solid #00BCBC;

    font-style: normal !important;
    font-weight: bold !important;
    text-align: center !important;
    //background-color: #00BCBC !important;
    background-color: var(--btn-primary-bg-color) !important;
    border: none !important;
    color: white !important;
    padding: 15px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 16px !important;
    margin: 4px 4px !important;
    cursor: pointer !important;
    font-family: "SiemensSans-Bold", sans-serif;
}
.dis-filed1 {
    // outline:none;
    //border-bottom: 1px solid #00BCBC;

    font-style: normal;
    font-weight: bold;
    text-align: center;
    //background-color: #00BCBC !important;
    background-color: grey;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 4px;
    cursor: pointer;
    font-family: "SiemensSans-Bold", sans-serif;
}


//span {
    //font-size: 10px;
//}
.fname {
    font-size: 10px;
   width:min-content;
}
.disabled-link {
    border: none;
    outline: none;
    //color: #00BCBC;
    font-style: italic;
    border-bottom: 1px solid #00BCBC;
    font-weight: bold;
    text-align: center;
}
.new-reject{
    text-align:center;
    margin-bottom:10px;
}
.remark-content{
    display:none;
}
.seperator {
    border-bottom: 2px dashed #008aa6;
    //display: flex;
    padding-bottom: 15px;
}


.background {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}
.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.hidden{
    display:none;
}
.show{
    display:flex;
}
.cancel-btn{
    margin:10px !important;
}
.apprv-btn {
    margin-right: 20px;
}
.btns-apprrej{
    text-align:center;
}
.first-btn{
    margin-right:20px;
}
.hide-hidden{
    display:none;
}
.dis-link{
    cursor:default;
    pointer-events:none;
}

.test.MuiDialog-paper {
    background: red !important;
}
.snackgreen.MuiDialog-paper {
    background: red !important;
    color:red!important;
}
/*.disabled-link {
    pointer-events: none;
}*/
.bord {
    border: 1px solid #008aa6;
    margin: 5px;
    font-size:15px;
    text-align: center;
}