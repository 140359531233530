.mr-bottom-upload {
    margin-bottom: 20px;
    padding: 10px;
}

.paper-style {
    // padding: 15px;
    margin: 10px;
    position: relative;
    display: flex;
    margin-bottom: 20px;
    box-shadow: 0 0 10px var(--light-sand);
}

.margin-rm {
    margin: 10px;
}

.paper-pd {
    padding: 10px;
}

.h3-upload {
    margin: 10px;
}

.mt-upload {
    margin-right: 21px;
}
.mandatoryText {
    color: var(--red);
    font-family: "SiemensSans-Bold";
}

.fileSize {
    margin-left: 50px;
}
/*.marginChange{
    margin-top: 20px;
}*/