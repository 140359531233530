
$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.adminMenu-item-container {
    position: relative;
    display: flex;
    background: var(--landing-page-card-bg-color);
    box-shadow: 0 0 10px var(--light-sand);
    border-radius: 8px;

    .adminMenu-item-text-block {
        padding: 20px;
    }
}
.Admin-block{
    margin-top: 40px !important;
    }
