$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.Complaints-block{
margin-top: 40px !important;
}
.complaintMenu-item-container {
    position: relative;
    display: flex;
    background: var(--landing-page-card-bg-color);
    box-shadow: 0 0 10px var(--light-sand);
    border-radius: 8px;

    .complaintMenu-item-text-block {
        padding: 20px;
    }
}
.MuiDialog-paperWidthSm {
    max-width: 850px !important;
}
.content-center{
    align-self: center;
}
.view-flex{
display: flex;
}
.partdesc-top{
    margin-top: 18px !important;
}
.fileMatTable {
    .MuiFormHelperText-root {
      color: black;
      font-size: 12px;
      font-family: "SiemensSans-Black", sans-serif;
    }
  }
  .Table-margin{
    .MuiTableCell-body{
        white-space: pre
    }
  }
  .gridCenter {
    text-align: center;
  }