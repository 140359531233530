.margin-d8{
    text-align: -webkit-center;
}
.fileMatTable {
   
    .MuiFormHelperText-root {
      color: black;
      font-size: 12px;
      font-family: "SiemensSans-Black", sans-serif;
    }
   
  }
  .Table-Margin{
    .MuiTableCell-body{
        white-space: pre
    }
  }
  .pd-btm{
    padding-bottom: 20px;
  }
 